import React, { type ReactNode } from "react";
import { View } from "react-native";
import { ContentArea, FooterSpacer, Surface } from "../../atoms";
import { useStickyFooter } from "../../atoms/FooterContent";
import { ScreenScroll } from "../../layout";
import type { StepperLayoutProps } from "../../layout/Stepper";
import type { StepConfig } from "../../layout/Stepper/Stepper.types";
import { Divider, Row, Spacer } from "../../quarks";
import { useMatchesViewport, useStyles } from "../../style";
import { useThrottledState } from "../../utils";
import { getScrollbarWidth } from "../../utils/measurements";
import { StepFooter } from "./StepFooter";
import { StepHeader } from "./StepHeader";
import { StepIndicatorLabel } from "./StepIndicator";

type OverrideProps = {
  testID: string;
  stickyFooter?: "never" | "always" | "auto";
};

type Props = StepperLayoutProps<OverrideProps> & { header?: JSX.Element };

export function StepWithIndicators({
  header: stepperHeader,
  current,
  steps,
  children
}: Props) {
  return (
    <StepWithIndicatorsLayout
      header={stepperHeader}
      testID={current.options.testID}
      stickyFooter={current.options.stickyFooter}
      horizontalIndicator={
        <HorizontalIndicator steps={steps} current={current.index} />
      }
      verticalIndicator={
        <VerticalIndicator steps={steps} current={current.index} />
      }
    >
      {children}
    </StepWithIndicatorsLayout>
  );
}

type StepWithIndicatorsLayoutProps = {
  testID: string;
  header?: ReactNode;
  stickyFooter?: "never" | "always" | "auto";
  children?: ReactNode;

  /// only used by steppers
  horizontalIndicator?: JSX.Element;
  verticalIndicator?: JSX.Element;
};

export function StepWithIndicatorsLayout({
  header,
  testID,
  stickyFooter = "auto",
  horizontalIndicator,
  verticalIndicator,
  children
}: StepWithIndicatorsLayoutProps) {
  const isLg = useMatchesViewport(({ size }) => size.large.up);
  const [contentHeight, setContentHeight] = useThrottledState(0);
  const [height, setHeight] = useThrottledState(0);
  const shouldGrow = !isLg; // makes the content grow on mobile
  const shouldUseStickyFooter =
    stickyFooter === "always" ||
    (stickyFooter === "auto" && (!isLg || contentHeight > height));

  const styles = useStyles(
    ({ getUnits, getColor }) => ({
      footerSticky: {
        position: "absolute",
        left: 0,
        right: getScrollbarWidth(),
        paddingLeft: getUnits(4),
        paddingRight: getUnits(4),
        backgroundColor: getColor(isLg ? "background" : "surface", "fill", {
          opacity: 0.8
        })
      },
      footerInline: {
        marginTop: "auto",
        paddingLeft: getUnits(4),
        paddingRight: getUnits(4)
      }
    }),
    [isLg]
  );

  const { bottom, contentSize, onLayout } = useStickyFooter({
    enabled: shouldUseStickyFooter
  });

  const footer = (
    <View
      style={
        shouldUseStickyFooter
          ? [styles.footerSticky, { bottom }]
          : styles.footerInline
      }
      onLayout={onLayout}
    >
      <StepFooter.Exit />
    </View>
  );

  return (
    <StepHeader.Realm>
      <StepFooter.Provider stickyFooter={shouldUseStickyFooter}>
        <ScreenScroll
          testID={testID}
          automaticallyAdjustContentInsets={false}
          keyboardDismissMode="none"
          enableOnAndroid={false}
          enableResetScrollToCoords={false}
          color={isLg ? "background" : "surface"}
          onContentSizeChange={(_, h) => setContentHeight(h)}
          onLayout={(e) => setHeight(e.nativeEvent.layout.height)}
          footer={shouldUseStickyFooter && footer}
          grow={shouldGrow}
        >
          {header ?? <StepHeader.Exit />}
          {!isLg && horizontalIndicator}

          {verticalIndicator ? (
            <Row gap="large" grow={shouldGrow}>
              {isLg && verticalIndicator}
              <Surface
                fill
                variant={isLg ? "shadow" : "flat"}
                testID={`${testID}-content`}
              >
                {children}
                {!shouldUseStickyFooter && footer}
              </Surface>
            </Row>
          ) : (
            <Surface
              grow={shouldGrow}
              variant={isLg ? "shadow" : "flat"}
              testID={`${testID}-content`}
            >
              {children}
              {!shouldUseStickyFooter && footer}
            </Surface>
          )}
          {shouldUseStickyFooter ? (
            <View style={{ height: contentSize }} />
          ) : (
            <FooterSpacer />
          )}
        </ScreenScroll>
      </StepFooter.Provider>
    </StepHeader.Realm>
  );
}

type IndicatorProps = {
  steps: StepConfig<unknown, unknown>[];
  current: number;
};

function VerticalIndicator({ steps, current }: IndicatorProps) {
  return (
    <ContentArea>
      {steps.map((step, index) => (
        <StepIndicatorLabel
          key={step.name}
          label={step.name}
          num={index + 1}
          first={index === 0}
          active={step.index <= current}
        />
      ))}
    </ContentArea>
  );
}

function HorizontalIndicator({ steps, current }: IndicatorProps) {
  const isMd = useMatchesViewport(({ size }) => size.medium.up);
  const styles = useStyles(({ getColor, getUnits }) => ({
    container: {
      paddingVertical: getUnits(5)
    },
    connector: {
      flex: 1,
      height: getUnits(2),
      backgroundColor: getColor("inactiveStep", "fill")
    },
    active: {
      backgroundColor: getColor("primary", "fill")
    }
  }));

  return (
    <ContentArea
      {...(isMd
        ? { size: "large", variant: "xlarge", constraint: "small" }
        : {
            size: "large",
            constraint: "xsmall",
            constrainedVariant: "none"
          })}
    >
      <Row gap={2}>
        {steps.map((step) => (
          <View
            key={step.name}
            style={[styles.connector, step.index <= current && styles.active]}
          />
        ))}
      </Row>
      <Spacer size="large" />
      <Divider />
    </ContentArea>
  );
}
