import { compact, uniq } from "lodash";
import { DateTime } from "luxon";
import React, { useLayoutEffect, useState } from "react";
import type { ComponentProps } from "react";
import { Platform } from "react-native";
import InputLabel from "../atoms/InputLabel";
import InputNote from "../atoms/InputNote";
import { MaskedTextInput, type TextInput } from "../molecules";
import { Column, Row, Spacer } from "../quarks";
import { useStyles } from "../style";
import Picker from "./Picker";

const OPTIONS_AM_PM = ["AM", "PM"];

type MeridiemType = "AM" | "PM";

interface Props
  extends Omit<
    ComponentProps<typeof TextInput>,
    "testID" | "value" | "label" | "onChange" | "errors" | "onBlur"
  > {
  testID: string;
  label?: string;
  reference?: string | DateTime | null | undefined;
  value?: DateTime | null | undefined;
  timezone?: string | null | undefined;
  onChange?: (date: DateTime | null) => void | Promise<void>;
  errors?: string | string[] | Error | Error[] | null;
  onBlur?: () => void;
  initialMeridiem?: MeridiemType | null;
  fullWidth?: boolean;
  minWidth?: number;
}

export default function TimeInput({
  fill,
  onChange,
  value,
  errors,
  label,
  testID,
  reference,
  timezone,
  initialMeridiem,
  note,
  fullWidth = true,
  showErrorMessage = true,
  minWidth = 60,
  onBlur,
  onFocus
}: Props) {
  const [time, setTime] = useState("");
  const [meridiem, setMeridiem] = useState(() => {
    if (initialMeridiem) return initialMeridiem;

    return DateTime.local({ zone: timezone ?? undefined }).toFormat(
      "a"
    ) as MeridiemType;
  });

  const styles = useStyles(
    () => ({
      input: {
        minWidth,
        grow: 1
      },
      meridiem: {
        maxWidth: 114
      }
    }),
    [minWidth]
  );

  useLayoutEffect(() => {
    if (!value) return;
    const [newTime, newMeridiem] = value.toFormat("hh:mm a").split(" ");
    if (newTime && !isSameTime(newTime, time)) setTime(newTime);
    if (newMeridiem && newMeridiem !== meridiem) {
      setMeridiem(newMeridiem as MeridiemType);
    }
  }, [value]);

  useLayoutEffect(() => {
    const value =
      time.length > 3 && isValidDate(time, meridiem)
        ? getDateTimeFromInputValues(time, meridiem, reference, timezone)
        : null;

    onChange?.(value);
  }, [time, meridiem]);

  const isValid = isValidDate(time, meridiem);
  const errorMessage = !isValid
    ? "Invalid Time"
    : Array.isArray(errors)
      ? uniq(compact(errors.map(formatError))).join(", ")
      : formatError(errors);
  const showError =
    time.length > 3 && !!meridiem && !!errorMessage && showErrorMessage;
  const helperText = showError ? errorMessage : note;
  console.log("@@@2.2.", { value, time, meridiem, errorMessage, errors });

  return (
    <Column fill={fill}>
      {!!label && <InputLabel label={label} />}
      {!!label && <Spacer size="slim" />}
      <Row gap="compact">
        <MaskedTextInput
          fill={fullWidth}
          containerStyle={styles.input}
          testID={`${testID}-hm-input`}
          value={time}
          mask="time"
          placeholder="-- : --"
          onChangeText={setTime}
          keyboardType={Platform.OS === "web" ? "default" : "number-pad"}
          onBlur={onBlur}
          onFocus={onFocus}
        />
        <Picker
          fill={fullWidth}
          containerStyle={[styles.input, styles.meridiem]}
          eventTargetName="Meridiem Picker"
          testID={`${testID}-meridiem`}
          value={meridiem}
          placeholder="--"
          options={OPTIONS_AM_PM}
          onChange={(meridiem) =>
            meridiem && setMeridiem(meridiem as MeridiemType)
          }
        />
      </Row>
      {!!helperText && (
        <InputNote
          error={showError}
          testID="validator-error"
          note={helperText}
        />
      )}
    </Column>
  );
}

function formatError(error?: Error | string | null) {
  if (error) return typeof error === "string" ? error : error.message;
}

export function getDateTimeFromInputValues(
  time: string,
  meridiem?: MeridiemType | null,
  reference?: string | DateTime | null,
  timezone?: string | null
): DateTime | null {
  const { hours, minutes } = getHoursAndMinutes(time);

  let date =
    typeof reference === "string"
      ? DateTime.fromISO(reference)
      : reference ?? DateTime.local();
  date = date.setZone(timezone ?? undefined);

  let computedHours = hours + (meridiem === "PM" ? 12 : 0);
  if (hours === 12) computedHours = meridiem === "AM" ? 0 : 12;

  date = date.set({
    hour: computedHours,
    minute: minutes,
    second: 0,
    millisecond: 0
  });
  return date.isValid ? date : null;
}

function getHoursAndMinutes(time: string) {
  const [hours = 0, minutes = 0] = time
    ? time.split(":").map((num) => (num ? Number.parseInt(num, 10) : 0))
    : [];
  return { hours, minutes };
}

const isValidDate = (time: string, meridiem?: MeridiemType | null) => {
  if (meridiem !== "AM" && meridiem !== "PM") return false;
  const { hours, minutes } = getHoursAndMinutes(time);
  return hours > 0 && hours < 13 && minutes < 60;
};

const isSameTime = (t1: string, t2: string) => {
  const v1 = getHoursAndMinutes(t1);
  const v2 = getHoursAndMinutes(t2);
  return v1.hours === v2.hours && v1.minutes === v2.minutes;
};
